import { hasCharactersAfterStringInURL } from "util/common";
import { clickEvents } from "./constants";
import { listProductTypes } from "util/types";
import { COUNTRIES } from "util/countryListIcons";

const ID_PREFIX_SEPARATOR = "__";

export const handleClickEvent = (e, collectAnalytics) => {
	// id of the element should be __store_tab_click__sales for example => sales is the value (sales tab was clicked)
	let elementId = findParentWithId(e.target, ID_PREFIX_SEPARATOR);
	if (!elementId) {
		return;
	}
	const eventId = elementId.slice(2);
	const splittedEventId = eventId.split(ID_PREFIX_SEPARATOR);
	const eventName = splittedEventId[0];
	const eventPayload = {};
	if (splittedEventId.length > 1) {
		// event ID has a value associate with
		const eventValue = splittedEventId[1];
		const eventData = clickEvents[eventName];
		if (eventData) {
			eventPayload[eventData.field] = eventValue;
		}
	}
	if (!eventName) {
		return;
	}
	collectAnalytics(eventName, eventPayload);
};

const findParentWithId = (
	clickedElementTarget,
	desiredIdPrefix,
	maxDepth = 4,
) => {
	// pass maxDepth -1 for entire DOM search
	let node = clickedElementTarget;
	let counter = maxDepth;
	while (node) {
		if (counter === 0) {
			return;
		}
		if (node.id.startsWith(desiredIdPrefix)) {
			return node.id;
		}
		node = node.parentElement;
		counter -= 1;
	}
};

export const getEventScopeFromUrl = (path, row) => {
	const storesScope = ["/stores/sales/", "/stores/avg-discounts/", "/stores/"];

	const isSingleProductPage =
		path.startsWith("/products/") && path !== "/products/";

	const isSingleProductPageWidget = /\/product\/[^\/]+\/stores/.test(path);

	if (path === "/widget/home/stores") {
		return "stores";
	}
	if (
		storesScope.includes(path) ||
		isSingleProductPage ||
		isSingleProductPageWidget
	) {
		return "stores";
	} else {
		return "products";
	}
};

export const getPayloadFromExpandedRow = (row, path) => {
	const eventScope = getEventScopeFromUrl(path, row);
	let eventPayload;
	let eventName;
	switch (eventScope) {
		case "products":
			// product row expanded
			eventName = "product_row_expanded";
			eventPayload = {
				product_id: row.id,
				...(row.store_id && {
					store_id: row.store_id,
					display_name: row?.display_name,
				}),
			};
			break;
		case "stores":
			eventName = "pharmacy_row_expanded";
			eventPayload = {
				store_id: row.id,
				display_name: row.display_name,
			};
			break;
	}
	return { eventName, eventPayload };
};

export const getProductsEventScopeFromUrl = (
	path,
	isInIframe = false,
	router,
	cache,
) => {
	// PRODUCTS

	// subdomain.jane.co.il/
	if (path === "/") {
		console.log("i hit subdomain event");

		return {
			scope: "products",
			website: "Jane subdomain store",
			item_list_name: "store page",
			type: "product",
		};
	}

	// /products/
	if (/^\/products\/(\?[\w=&%^-_]+)?$/.test(path)) {
		return {
			scope: "products",
			website: "Jane: main products page",
			item_list_name: "products lists pages",
			type: "product",
		};
	}
	// /products/newest
	if (/^\/products\/newest\/(\?[\w=&%^-_]+)?$/.test(path)) {
		return {
			scope: "products",
			website: "Jane: newest products",
			item_list_name: "products lists pages",
			type: "product",
		};
	}
	// /products/soon
	if (/^\/products\/soon\/(\?[\w=&%^-_]+)?$/.test(path)) {
		return {
			scope: "products",
			website: "Jane: soon products",
			item_list_name: "products lists pages",
			type: "product",
		};
	}
	// /marketer/:id
	if (/^\/marketer\/[\w-]+\/(\?[\w=&%^-_]+)?$/.test(path)) {
		return {
			scope: "products",
			website: "Jane marketer",
			item_list_name: "marketer page",
			type: "product",
		};
	}
	// /manufacturer/:id
	if (/^\/manufacturer\/[\w-]+\/(\?[\w=&%^-_]+)?$/.test(path)) {
		return {
			scope: "products",
			website: "Jane manufacturer",
			item_list_name: "manufacturer page",
			type: "product",
		};
	}
	// /cities/:id/products
	if (/^\/cities\/[0-9]{1,4}\/products\/?(\?[\w=&%^-_]+)?$/.test(path)) {
		const { id } = router.query;
		const city = cache.allCities.find(city => city.id === parseInt(id));
		const website = `Jane city: ${city.heb_name}`;

		return {
			scope: "products",
			website: website,
			item_list_name: "city page",
			type: "product",
		};
	}
	// /strains/:id
	if (/^\/strain\/[\w-]+\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const { id } = router.query;
		const strain = cache.strains.find(strain => strain.id === id);
		const website = `Jane strain: ${strain.heb_name}`;

		return {
			scope: "products",
			website: website,
			item_list_name: "strain page",
			type: "product",
		};
	}
	// /desired-cart/:id?
	if (/^\/desired-cart\/.*$/.test(path) && !path.includes("stores")) {
		return {
			scope: "products",
			website: "Jane desired cart",
			item_list_name: "desired cart page",
			type: "product",
		};
	}
	// /store/:id?
	if (/^\/store\/[\w-]+\/(\?[\w=&%^-_]+)?$/.test(path)) {
		return {
			scope: "products",
			website: "Jane store",
			item_list_name: "store page",
			type: "product",
		};
	}

	// WIDGETS + IFRAMES
	// /widget/products/ + iframe
	if (/^\/widget\/products\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz: main products page"
			: "Jane widget: main products page";
		return {
			scope: "products",
			website: website,
			item_list_name: "products lists pages",
			type: "product",
		};
	}
	// /widget/store/:id? + iframe
	if (/^\/widget\/store\/[\w-]+\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz store"
			: "Jane widget store";
		return {
			scope: "products",
			website: website,
			item_list_name: "store page",
			type: "product",
		};
	}
	// /widget/store/:id/tablet + iframe
	if (/^\/widget\/store\/[\w-]+\/tablet\/(\?[\w=&%^-_]+)?$/.test(path)) {
		return {
			scope: "products",
			website: "Jane tablet store",
			item_list_name: "store page",
			type: "product",
		};
	}
	// /widget/marketer/:id + iframe
	if (/^\/widget\/marketer\/[\w-]+\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz marketer"
			: "Jane widget marketer";
		return {
			scope: "products",
			website: website,
			item_list_name: "marketer page",
			type: "product",
		};
	}
	// /widget/manufacturer/:id + iframe
	if (/^\/widget\/manufacturer\/[\w-]+\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz manufacturer"
			: "Jane widget manufacturer";
		return {
			scope: "products",
			website: website,
			item_list_name: "manufacturer page",
			type: "product",
		};
	}
	// /widget/products/sale + iframe
	if (/^\/widget\/products\/sale\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz: sale"
			: "Jane widget: sale";
		return {
			scope: "products",
			website: website,
			item_list_name: "products lists pages",
			type: "product",
		};
	}
	// /widget/products/price + iframe
	if (/^\/widget\/products\/price\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz: price"
			: "Jane widget: price";
		return {
			scope: "products",
			website: website,
			item_list_name: "products lists pages",
			type: "product",
		};
	}
	// /widget/products/newest + iframe
	if (/^\/widget\/products\/newest\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz: newest products"
			: "Jane widget: newest products";
		return {
			scope: "products",
			website: website,
			item_list_name: "products lists pages",
			type: "product",
		};
	}
	// /widget/products/soon + iframe
	if (/^\/widget\/products\/soon\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz: soon products"
			: "Jane widget: soon products";
		return {
			scope: "products",
			website: website,
			item_list_name: "products lists pages",
			type: "product",
		};
	}
	// widget/cities/:id/ (redirect to /city/:id) + iframe
	if (/^\/widget\/city\/[0-9]{1,4}\/?(\?[\w=&%^-_]+)?$/.test(path)) {
		const { id } = router.query;
		const city = cache.allCities.find(city => city.id === parseInt(id));
		const website = isInIframe
			? `iframe in cannabiz: city ${city.heb_name}`
			: `Jane widget city: ${city.heb_name}`;

		return {
			scope: "products",
			website: website,
			item_list_name: "city page",
			type: "product",
		};
	}
	// /widget/desired-cart/:id? + iframe
	if (/^\/widget\/desired-cart\/.*$/.test(path) && !path.includes("stores")) {
		const website = isInIframe
			? "iframe in cannabiz: desired cart"
			: "Jane widget: desired cart";
		return {
			scope: "products",
			website: website,
			item_list_name: "desired cart page",
			type: "product",
		};
	}
	// /widget/products/flower + iframe
	if (/^\/widget\/products\/flower\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz: flower"
			: "Jane widget: flower";
		return {
			scope: "products",
			website: website,
			item_list_name: "type: cannabis inflorescences",
			type: "product",
		};
	}
	// /widget/products/preroll + iframe
	if (/^\/widget\/products\/preroll\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz: preroll"
			: "Jane widget: preroll";
		return {
			scope: "products",
			website: website,
			item_list_name: "type: cannabis preroll",
			type: "product",
		};
	}
	// /widget/products/oil + iframe
	if (/^\/widget\/products\/oil\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe ? "iframe in cannabiz: oil" : "Jane widget: oil";
		return {
			scope: "products",
			website: website,
			item_list_name: "type: cannabis oil",
			type: "product",
		};
	}
	// /widget/products/balanced + iframe
	if (/^\/widget\/products\/balanced\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz: balanced"
			: "Jane widget: balanced";
		return {
			scope: "products",
			website: website,
			item_list_name: "power: balanced cannabis",
			type: "product",
		};
	}
	// /widget/products/balanced + iframe
	if (/^\/widget\/products\/cbd\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe ? "iframe in cannabiz: cbd" : "Jane widget: cbd";
		return {
			scope: "products",
			website: website,
			item_list_name: "power: cbd cannabis",
			type: "product",
		};
	}
	// /widget/category/:categoryid + iframe
	if (/^\/widget\/category\/[\w]+\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const { id } = router.query;
		const website = isInIframe
			? `iframe in cannabiz: ${id}`
			: `Jane widget: ${id}`;

		return {
			scope: "products",
			website: website,
			item_list_name: `dosage: ${id}`,
			type: "product",
		};
	}

	// Widget/home/products + iframe
	if (/^\/widget\/home\/products\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz: main products table: all products"
			: "Jane widget: main products table: all products";
		return {
			scope: "products",
			website: website,
			item_list_name: "products lists pages",
			type: "product",
		};
	}

	// Widget/home/newest + iframe
	if (/^\/widget\/home\/newest\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz: main products table: newest"
			: "Jane widget: main products table: newest";
		return {
			scope: "products",
			website: website,
			item_list_name: "products lists pages",
			type: "product",
		};
	}

	// Widget/home/soon + iframe
	if (/^\/widget\/home\/soon\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz: main products table: soon"
			: "Jane widget: main products table: soon";
		return {
			scope: "products",
			website: website,
			item_list_name: "products lists pages",
			type: "product",
		};
	}

	// Widget/home/price + iframe
	if (/^\/widget\/home\/price\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz: main products table: cheap"
			: "Jane widget: main products table: cheap";
		return {
			scope: "products",
			website: website,
			item_list_name: "products lists pages",
			type: "product",
		};
	}

	// Widget/home/price + iframe
	if (/^\/widget\/home\/most-viewed\/(\?[\w=&%^-_]+)?$/.test(path)) {
		const website = isInIframe
			? "iframe in cannabiz: main products table: views"
			: "Jane widget: main products table: views";
		return {
			scope: "products",
			website: website,
			item_list_name: "products lists pages",
			type: "product",
		};
	}

	return null;
};

export const getStoresEventScopeFromUrl = (path, isInIframe = false) => {
	// STORES

	//Widget/home iframe
	if (/^\/widget\/home\/stores\/.*/.test(path)) {
		return { scope: "stores", website: "stores iframe", type: "store" };
	}
	// Widget avg discounts
	if (/^\/widget\/stores\/avg-discounts\/.*/.test(path)) {
		return { scope: "stores", website: "avg-discounts widget", type: "store" };
	}

	//Jane
	if (/^\/stores\/avg-discounts\/.*/.test(path)) {
		return { scope: "stores", website: "avg-discounts", type: "store" };
	}
	if (/^\/stores\/\?.+/.test(path)) {
		// stores with filter
		return { scope: "stores", website: "stores", type: "store" };
	}
	if (/^\/stores\/$/.test(path)) {
		// stores page exactly
		return { scope: "stores", website: "stores", type: "store" };
	}
	if (/^\/desired-cart\/.*/.test(path) && path.includes("stores")) {
		return { scope: "stores", website: "Jane desired cart", type: "store" };
	}
	if (/^\/widget\/desired-cart\/.*/.test(path) && path.includes("stores")) {
		return {
			scope: "stores",
			website: "Jane widget desired cart",
			type: "store",
		};
	}
	if (/^\/product\/\w.*/.test(path)) {
		// single product page jane
		return { scope: "stores", website: "Jane product", type: "store" };
	}
	if (/^\/city\/\w.*/.test(path)) {
		return { scope: "stores", website: "cities", type: "store" };
	}
	if (/^\/widget\/product\/\w.*\/stores/.test(path)) {
		if (isInIframe) {
			return {
				scope: "stores",
				website: "iframe in cannabiz product",
				type: "store",
			};
		}
		return { scope: "stores", website: "Jane widget product", type: "store" };
	}

	return null;
};

export const getPharmacyRowExpandedGoogleEvent = (
	row,
	path,
	isInIframe = false,
) => {
	const eventScope = getStoresEventScopeFromUrl(path, isInIframe);
	if (!eventScope) {
		return;
	}

	if (eventScope.website === "cities") {
		//  we dont want to track cities products page - only case in all website
		return;
	}

	if (
		[
			"sales",
			"discounts",
			"stores",
			"Jane desired cart",
			"stores iframe",
			"sales iframe",
			"avg-discounts",
		].includes(eventScope.website)
	) {
		const affiliation = `${row?.display_name} - ${row?.city?.heb_name}`;
		const event = {
			event: "select_item",
			ecommerce: {
				items: [
					{
						affiliation: affiliation,
						item_name: "none",
						item_id: "none",
						item_list_name: eventScope.website,
						website: eventScope.website,
					},
				],
			},
		};
		return event;
	} else {
		// Single store pages
		const affiliation = `${row?.display_name} - ${row.city?.heb_name}`;
		const id = row.product_id;
		const itemName = `${row?.product?.heb_name} ${row?.product?.eng_name}`;
		const event = {
			event: "select_item",
			ecommerce: {
				items: [
					{
						affiliation: affiliation,
						item_name: itemName,
						item_id: id,
						item_list_name: "item page",
						website: eventScope.website,
					},
				],
			},
		};
		return event;
	}
};

export const getProductsImpressionGoogleEvent = (
	path,
	rows,
	isInIframe,
	router,
	cache,
) => {
	const eventScope = getProductsEventScopeFromUrl(
		path,
		isInIframe,
		router,
		cache,
	);
	if (!eventScope) {
		return;
	}

	const eventItems = rows.map(product => {
		const productEventData = getProductEventData(product);
		return {
			...productEventData,
			item_list_name: eventScope.item_list_name,
			website: eventScope.website,
		};
	});

	const event = {
		event: "view_item_list",
		ecommerce: {
			items: eventItems,
		},
	};
	return event;
};

export const getPharmacyImpressionGoogleEvent = (path, rows, isInIframe) => {
	const eventScope = getStoresEventScopeFromUrl(path, isInIframe);
	if (!eventScope) {
		return;
	}

	if (
		[
			"sales",
			"discounts",
			"stores",
			"Jane desired cart",
			"stores iframe",
			"sales iframe",
			"cities",
			"avg-discounts",
		].includes(eventScope.website)
	) {
		const isCitysProductRows = rows.some(row => row?.catalog_price);
		if (eventScope.website === "cities" && isCitysProductRows) {
			return;
		}

		const eventItems = rows.map(store => {
			return {
				affiliation: store?.display_name + " - " + store.city?.heb_name,
				item_name: "none",
				item_id: "none",
				item_list_name: eventScope.website,
				website: eventScope.website,
			};
		});

		const event = {
			event: "view_item_list",
			ecommerce: {
				items: eventItems,
			},
		};
		return event;
	} else {
		const eventItems = rows.map(store => {
			const affiliation = `${store?.display_name} - ${store?.city?.heb_name}`;
			const id = store?.product_id;
			const itemName = `${store?.product?.heb_name} ${store?.product?.eng_name}`;
			return {
				affiliation: affiliation,
				item_name: itemName,
				item_id: id,
				item_list_name: "item page",
				website: eventScope.website,
			};
		});

		const event = {
			event: "view_item_list",
			ecommerce: {
				items: eventItems,
			},
		};
		return event;
	}
};

export const getPharmacyPageViewGoogleEvent = (store, website) => {
	const affiliation = `${store?.display_name} - ${store.city?.heb_name}`;
	const event = {
		event: "view_item_list",
		ecommerce: {
			items: [
				{
					affiliation: affiliation,
					item_name: "none",
					item_id: "none",
					item_list_name: "store page",
					website: website,
				},
			],
		},
	};
	return event;
};

export const getWidgetHomeTabSwitchEvent = () => {
	const event = {
		event: "moving from page to page",
	};
	return event;
};

const getProductEventData = product => {
	const firstParents = product?.parents.map(({ heb_name }) =>
		heb_name.replace("'", "").trim(),
	);

	const secondParents = product?.parents_second.map(({ heb_name }) =>
		heb_name.replace("'", "").trim(),
	);

	const parents = [...firstParents, ...secondParents].join(", ");

	const country = COUNTRIES.find(
		country => country.code === product?.origin_country,
	)?.heb_name;

	const productType = listProductTypes.find(type => {
		return type.label === product.product_type;
	}).longLabel;

	const family = listProductTypes.find(type => {
		return type.label === product.family;
	}).longLabel;

	return {
		item_name: `${product.heb_name} ${product.eng_name}`,
		item_id: product.id,
		price: product.catalog_price,
		currency: "ILS",
		heb_name: product.heb_name,
		eng_name: product.eng_name,
		product_type: productType,
		item_category: product.category,
		family: family,
		marketer: product?.marketer_heb_name,
		manufacturer: product?.manufacturer_heb_name,
		manufacturer_series: product?.manufacturer_series_heb_name,
		parents: parents,
		series: product?.series_heb_name,
		item_variant: country,
		quantity: "1",
	};
};
